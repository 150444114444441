import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import image from '../../../images/News/NewsDefault.png';
import { Link } from 'react-router-dom';
import { Block, Icon } from '../../../Components/Component';
import { ShimmerContentBlock, ShimmerPostItem, ShimmerTitle } from 'react-shimmer-effects';
import { getRequest } from '../../../utils/api-header';

const MoreNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchNewsData = async () => {
        try {
            const response = await getRequest('api/v1/public/get-public-news?limit=20&order=desc');
            if (response.status && Array.isArray(response.data.news)) {
                const allNews = response.data.news;
                setNewsData(allNews.slice(14, 18));
            } else {
                setNewsData(staticNewsData);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setNewsData(staticNewsData);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewsData();
    }, []);

    const staticNewsData = [
        { id: 1, title: "Team USA comes back from 17-point deficit to survive Serbia, advances to the gold medal game", summary: "Team USA on Thursday had to produce a breathtaking comeback to defeat Serbia 95-91 and advance to the men’s basketball gold medal game." },
        { id: 2, title: "Second news item title goes here.", summary: "Second news item summary goes here." },
        { id: 3, title: "Third news item title goes here.", summary: "Third news item summary goes here." },
        { id: 4, title: "Fourth news item title goes here.", summary: "Fourth news item summary goes here." }
    ];

    const ShimmerSideNewsItem = () => (
        <div className='border-2 border-bottom py-3'>
            <ShimmerTitle line={2} />
        </div>
    );

    const renderShimmerContent = () => (
        <Container className='border-top border-3 border-primary pb-4'>
            <Block className={'mt-5'}>
                <div className='container-fluid py-3 px-0'>
                    <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>More News<Icon name="chevron-right" className={'align-bottom'} /></h4>
                </div>
                <Row>
                    <Col md={9}>
                        <div className='content border-bottom pb-4'>
                            <div className={`d-flex flex-column px-0 flex-md-row`}>
                                <div className='row'>
                                    <ShimmerContentBlock title text cta thumbnailWidth={370} thumbnailHeight={370} reverse />
                                </div>
                            </div>
                        </div>
                        <div className='content py-3'>
                            <Row>
                                {[0, 1].map((_, index) => (
                                    <Col md={6} key={index}>
                                        <ShimmerPostItem card title text cta />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='content'>
                            {[0, 1, 2, 3].map((_, index) => (
                                <ShimmerSideNewsItem key={index} />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Block>
        </Container>
    );

    if (loading) {
        return renderShimmerContent();
    }

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    const placeholderImage = image;

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    return (
        <Container className='border-top border-3 border-primary pb-4'>
            <Block className={'mt-4'}>
                <div className='container-fluid py-3 px-0'>
                    <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>More News<Icon name="chevron-right" className={'align-bottom'} /></h4>
                </div>
                <Row>
                    <Col md={12}>
                        <div className='content pb-4'>
                            <div className='d-flex flex-column px-0 flex-md-row'>
                                <Link to={`/news/${newsData[0]?.id || staticNewsData[0].id}`} className="image-zoom-container">
                                    <div className='row'>
                                        <div className='col-12 col-md-7'>
                                            <Card className='rounded-0 h-100'>
                                                <CardBody className="card-inner p-0 pt-3">
                                                    <CardTitle className='text-black fs-2 fw-medium ff'>
                                                        {newsData[0]?.title || staticNewsData[0].title}
                                                    </CardTitle>
                                                    <CardText>
                                                        {typeof newsData[0]?.summary === 'string' ? truncateText(newsData[0]?.summary || staticNewsData[0].summary, 300) : ''}
                                                    </CardText>
                                                    <CardFooter className='bg-transparent text-gray px-0'>
                                                        {new Date(newsData[0]?.publish_date || staticNewsData[0].publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                                                    </CardFooter>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className='col-12 col-md-5 mb-3 mb-md-0 pt-3'>
                                            <div className='image-zoom-container border border-1'>
                                                <figure className='m-0'>
                                                    <img src={newsData[0]?.image || image} alt='News' className='w-100 zoom-image' loading="lazy" onError={handleImageError} />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='content py-3'>
                            <Row className='g-gs'>
                                {newsData.slice(1, 4).map((item, index, title) => (
                                    <Col md={4} key={item.id || index}>
                                        <Link to={`/news/${item.id}`} className="text-danger image-zoom-container h-100" style={{ cursor: 'pointer' }}>
                                            <Card className="d-flex flex-column h-100">
                                                <div className='image-container border border-1 border-bottom-0'>
                                                    <CardImg top src={item.image || image} alt={item.title} loading="lazy" className='zoom-image rounded-0 h-100' onError={handleImageError} />
                                                </div>
                                                <CardBody className="card-inner p-3 border border-1 border-bottom-0">
                                                    <CardTitle tag="h5" className='ff text-black fs-4 fw-bold'>{item.title}</CardTitle>
                                                    <CardText className='fs-4'>
                                                        {typeof item.summary === 'string' ? truncateText(item.summary, 80) : ''}
                                                    </CardText>
                                                </CardBody>
                                                <CardFooter className='bg-transparent text-danger border border-1 border-top-0 text-danger ff d-flex justify-content-between'>
                                                    <p>Read More</p> <span className='text-gray'>{new Date(item.publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Block>
        </Container>
    );
};

export default MoreNews;
