import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardImg, CardText, Col, Container, Row } from 'reactstrap';
import { Icon } from '../../../Components/Component';
import placeholderimage from '../../../images/News/NewsDefault.png'

const NewsCard = React.memo(({ image, title, id, date, onError }) => (
  <div className='rounded-0 h-100'>
    <Link to={`/news/${id}`} className="text-decoration-none image-zoom-container h-100">
      <Card className="d-flex flex-column rounded-0 h-100 border border-1">
        <div className='image-container border-bottom border-bottom-1'>
          <CardImg top src={image || placeholderimage} alt={title} loading="lazy" className='zoom-image border-bottom border-bottom-1 rounded-0' onError={onError} />
        </div>
        <CardBody className="card-inner  py-2">
          <CardText tag="h5" className='text-black'>{title}</CardText>
        </CardBody>
        <CardText className='text-gray p-3 text-end'>{new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</CardText>
      </Card>
    </Link >
  </div>
));

const SectionOne = ({ categoryTitle, newsData }) => {
  const validatedNewsData = Array.isArray(newsData) ? newsData : [];

  const placeholderImage = placeholderimage;
  const handleImageError = (event) => {
    event.target.src = placeholderImage;
  };

  return (
    <Container className='border-top border-3 border-primary'>
      <div className='content py-md-4 py-2'>
        <div className='pb-2'>
          <h4 className='ff text-primary'>{categoryTitle}
            <Icon name="chevron-right" className={'align-bottom'} />
          </h4>
        </div>
        <Row xs={1} sm={2} md={3} lg={3} className='g-3'>
          {validatedNewsData.map((news) => (
            <Col key={news.id}>
              <NewsCard
                id={news.id}
                image={news.image || placeholderImage}
                title={news.title}
                date={news.publish_date}
                onError={handleImageError}
              // text={news.headline}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default SectionOne;