import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row, Container, CardImg, CardFooter } from 'reactstrap';
import { Icon } from '../../../Components/Component';
import img from '../../../images/personaimg/Temp/kamala_Haris.avif';
import { getRequest } from '../../../utils/api-header';
import { useNavigate } from 'react-router-dom';
import { ShimmerContentBlock, ShimmerSectionHeader } from 'react-shimmer-effects';
import placeholderimage from '../../../images/News/NewsDefault.png'

const SectionHeader = React.memo(({ title }) => (
    <div className='content py-3'>
        <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>
            {title} <Icon name="chevron-right" className={'align-bottom'} />
        </h4>
    </div>
));

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
};

const NewsItem = React.memo(({ isLast, id, image, title, summary, date, onError }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/news/${id}`);
    };

    return (
        <div className={`row px-2 px-md-0 pe-md-3 flex-column flex-md-row image-zoom-container  ${!isLast ? 'pb-4' : ''}`} onClick={handleClick}>
            <div className='col-12 col-md-3 mb-3 mb-md-0'>
                <figure className='m-0 image-zoom-container'>
                    <img
                        src={image || placeholderimage}
                        alt={title}
                        className='zoom-image img-fluid w-100 h-100'
                        loading="lazy"
                        onError={onError}
                        style={{ objectFit: 'cover', maxHeight: '200px' }}
                    />
                </figure>
            </div>

            {/* Content column */}
            <div className='col-12 col-md-9'>
                <Card className='rounded-0 h-100' onClick={handleClick} style={{ cursor: 'pointer' }}>
                    <CardBody className="card-inner py-0">
                        <CardTitle className='text-black fw-medium ff fs-4'>
                            {title}
                        </CardTitle>
                        {/* Uncomment if summary is needed */}
                        {/* <CardText>{typeof summary === 'string' ? truncateText(summary, 100) : ''}</CardText> */}
                        <CardFooter className='bg-transparent text-gray p-0 text-end'>
                            {new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                        </CardFooter>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
});

const RecentNewsCard = React.memo(({ id, title, image, date, onError }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/news/${id}`);
    };

    return (
        <Card className={`mt-0 h-100`} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <CardBody className="card-inner px-0 py-1 image-zoom-container h-100">
                <div className='image-zoom-container'>
                    <figure className='m-0 h-100'>
                        <CardImg top src={image || placeholderimage} alt={title} loading="lazy" className='zoom-image rounded-0 h-100' onError={onError} />
                    </figure>
                </div>
                <CardTitle className='text-black fw-medium ff m-0'>{title}</CardTitle>
                <CardFooter className='bg-transparent text-gray px-0'>
                    {new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                </CardFooter>
            </CardBody>
        </Card>
    );
});

const RecentNews = () => {
    const staticTrendingNewsData = useMemo(() => [
        { id: 1, image: img, title: "Trending News 1", text: "This is the content of the first trending news item." },
        { id: 2, image: img, title: "Trending News 2", text: "Content for the second trending news item goes here." },
        { id: 3, image: img, title: "Trending News 3", text: "Here's what the third trending news item is about." },
        { id: 4, image: img, title: "Trending News 4", text: "Here's what the fourth trending news item is about." },
        { id: 5, image: img, title: "Trending News 5", text: "Here's what the fifth trending news item is about." },
    ], []);

    const staticRecentNewsData = useMemo(() => [
        { id: 1, image: img, title: "This is the title of the first recent news item.", text: "This is the content of the first recent news item." },
        { id: 2, image: img, title: "Content for the second recent news item goes here.", text: "Content for the second recent news item goes here." },
        { id: 3, image: img, title: "Here's what the third recent news item is about.", text: "Here's what the third recent news item is about." },
        { id: 4, image: img, title: "Fourth recent item's content is displayed here.", text: "Fourth recent item's content is displayed here." },
        { id: 5, image: img, title: "Fifth recent item's content is displayed here.", text: "Fifth recent item's content is displayed here." },
        { id: 6, image: img, title: "Sixth recent item's content is displayed here.", text: "Sixth recent item's content is displayed here." },
    ], []);

    const [trendingNewsData, setTrendingNewsData] = useState([]);
    const [recentNewsData, setRecentNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const response = await getRequest('api/v1/public/get-public-news?limit=14&order=desc');
                if (response.status && Array.isArray(response.data.news)) {
                    const allNews = response.data.news;
                    setTrendingNewsData(allNews.slice(3, 8));
                    setRecentNewsData(allNews.slice(8, 14));
                } else {
                    throw new Error('Invalid data structure');
                }
            } catch (error) {
                console.error("Error fetching news data:", error);
                setTrendingNewsData(staticTrendingNewsData);
                setRecentNewsData(staticRecentNewsData);
            } finally {
                setLoading(false);
            }
        };
        fetchNewsData();
    }, []);

    const placeholderImage = placeholderimage;
    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    const renderShimmerTrendingNews = () => (
        Array(5).fill(0).map((_, index) => (
            <div key={index} className={`d-flex flex-column px-0 pe-3 flex-md-row ${index !== 4 ? 'border-bottom pb-4' : ''}`}>
                <ShimmerContentBlock title text cta thumbnailWidth={370} thumbnailHeight={370} />
            </div>
        ))
    );

    const renderShimmerRecentNews = () => (
        <Row>
            {Array(6).fill(0).map((_, index) => (
                <Col md={6} key={index}>
                    <Card className={`mt-0 ${index !== 5 ? 'border-bottom' : ''}`}>
                        <CardBody className="card-inner px-0">
                            <ShimmerSectionHeader />
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    );

    const newsItems = useMemo(() => {
        return loading
            ? renderShimmerTrendingNews()
            : trendingNewsData.map((item, index) => (
                <NewsItem
                    key={item.id}
                    id={item.id}
                    isLast={index === trendingNewsData.length - 1}
                    image={item.image || placeholderImage}
                    title={item.title}
                    summary={item.summary}
                    date={item.publish_date}
                    onError={handleImageError}
                />
            ));
    }, [trendingNewsData, loading]);

    const recentNewsCards = useMemo(() => {
        return loading
            ? renderShimmerRecentNews()
            : (
                <Row className="g-3">
                    {recentNewsData.map((item, index) => (
                        <Col lg={6} md={6} sm={6} xs={6} key={item.id}>
                            <RecentNewsCard
                                id={item.id}
                                isLast={index === recentNewsData.length - 1}
                                title={item.title}
                                image={item.image || placeholderImage}
                                date={item.publish_date}
                                onError={handleImageError}
                            />
                        </Col>
                    ))}
                </Row>
            );
    }, [recentNewsData, loading]);

    return (
        <Container className='py-4 border-top border-3 border-primary'>
            <Row className='g-gs justify-content-around'>
                <Col lg='8' md='7' sm='12' className='d-flex flex-column ps-lg-0'>
                    <SectionHeader title="Trending News" />
                    <div className="trending-news">
                        {newsItems}
                    </div>
                </Col>
                <Col lg='4' md='5' sm='12' className='d-flex flex-column border-start pe-lg-0'>
                    <SectionHeader title="Recent News" />
                    <div className="recent-news">
                        {recentNewsCards}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RecentNews;